/* global define, console, $, jQuery, lozad, loadjs */
'use strict';

let modalAlreadyLoaded = false;
// main core events
const coreFunctions = function () {
    $('.sidebar-toggler').on('click', function () {
        $('.sidebar-container').toggleClass('is-collapsed');
    });

    if (document.body.clientWidth < 768) {
        $('.sidebar-container').addClass('is-collapsed');
    }

    $('[data-toggle="tooltip"]').tooltip();

    $('#login-modal-btn').on('click', function (e) {
        e.preventDefault();
        let target = $('#modal-target');
        if (!modalAlreadyLoaded) {
            $.ajax({
                url: '/inc/ajax/login.php',
                type: 'POST',
                data: {
                    form_action: window.location.href
                }
            })
                .done(function (data) {
                    modalAlreadyLoaded = true;
                    target.html(data);
                    let run = window.run;
                    if (typeof run != 'undefined') {
                        setTimeout(run, 0);
                    }
                })
                .fail(function (data, statut, error) {
                    console.log(error);
                });
        } else {
            $('#login-modal').modal('show');
        }

        return false;
    });

    $('.trigger-modal-login').on('click', function () {
        $('#login-modal-btn').trigger('click');
        return false;
    });
};

loadjs([
    '/assets/javascripts/plugins/loaders/pace.min.js',
    '/assets/javascripts/jquery.min.js',
    '/assets/javascripts/popper.min.js',
    '/assets/javascripts/bootstrap.min.js'
], 'core',
    {
        async: false
    }
);

loadjs.ready('core', function () {
    coreFunctions();
    loadjs(['/assets/javascripts/mdb.min.js'], function () {
        loadjs.done('mdb');
    });
});
